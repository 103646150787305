import React from "react"
import Footer from "./Footer"
import Navigation from "./navbar"
import { Helmet } from "react-helmet"
import i18next from "i18next"
import { withTrans } from "../withTrans"

const Layout = ({ t, children }) => {
  return (
    <div>
      <Helmet>
        <html
          dir={i18next.language === `ar` ? `rtl` : `ltr`}
          lang={t(i18next.language)}
        />
      </Helmet>
      <div className="w-full h-full">
        <div className="bg-background-main bg-no-repeat w-full h-full bg-cover overflow-auto fixed top-0">
          <div className="lg:mx-auto lg:max-w-screen-lg lg:m-9 bg-white border-style">
            <Navigation t={t}> </Navigation>
            {children}
            <Footer t={t}></Footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTrans(Layout)
