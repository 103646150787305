import React, { useRef, useState, useEffect } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import { withTrans } from "../withTrans"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { localeLink } from "../utils/localeLink"
import NewsletterPopup from "../components/NewsletterPopup"

const Navigation = ({ t }) => {
  function langTargetToggle() {
    switch (i18next.language) {
      case "ar":
        return "en"

      case "en":
        return "ar"
      default:
        break
    }
  }

  const wrapperRef = useRef(null)
  const { i18n } = useTranslation()
  const [isExpanded, toggleExpansion] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  const handleMenuExpansion = event => {
    if (window.innerWidth >= 1024) {
      toggleExpansion(false)
    }
  }
  const handleClick = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      toggleExpansion(false)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleMenuExpansion, false)
    window.addEventListener("mousedown", handleClick, false)
  })

  function returnMenuList(data) {
    const menuList = []
    data.site.siteMetadata.menuLinks.forEach((menuItem, i) => {
      menuList.push(
        <div
          key={i}
          className={`${
            menuItem.link === "#" ? "in-progress" : "menu-item-hover"
          } menu-item block lg:inline-block lg:relative lg:text-center lg:mt-0 mt-4 lg:px-2 text-right`}
        >
          <Link
            activeClassName="font-futurastd_bold border-b border-dashed border-black"
            className="leading-5"
            to={localeLink(menuItem.link)}
          >
            <span>{t(menuItem.name)}</span>
          </Link>
        </div>
      )
      if (menuItem.separator) {
        menuList.push(
          <div className="hidden separator lg:inline-block align-middle lg:mt-0 mx-1"></div>
        )
      }
    })
    return menuList
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteQuery {
          site {
            siteMetadata {
              menuLinks {
                name
                link
                separator
              }
              menuSocialMedia {
                src
                link
              }
            }
          }
        }
      `}
      render={data => (
        <nav className="flex items-center justify-between flex-wrap bg-teal-500 m-3 lg:mx-7 nav-border pb-3">
          <div className="flex items-center flex-shrink-0 text-white lg:mt-12">
            <Link to={localeLink("/")}>
              <img className=" w-36" src="/img/Logo-01.svg" alt="logo" />
            </Link>
          </div>
          <div className="block lg:hidden">
            <button
              className="flex items-center px-3 py-2 border-teal-400 hover:text-white hover:border-white w-16"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 30 30"
              >
                <path d="M5,6.73l20.44-.47C25.27,9,25,10,24.69,10.54c-1.88.78-18.78.44-20.34.47a12,12,0,0,1,0-1.83A3.78,3.78,0,0,1,5,6.73Z" />
                <path d="M5,12.63l20.44-.46c-.12,2.77-.36,3.71-.7,4.27-1.88.78-18.78.44-20.34.47a11.93,11.93,0,0,1,0-1.83A3.78,3.78,0,0,1,5,12.63Z" />{" "}
                <path d="M5,18.54l20.44-.47c-.12,2.77-.36,3.71-.7,4.27-1.88.79-18.78.45-20.34.47A11.84,11.84,0,0,1,4.3,21,3.73,3.73,0,0,1,5,18.54Z" />
              </svg>
            </button>
          </div>
          <div
            ref={wrapperRef}
            className={`${
              isExpanded
                ? `flex absolute w-3/5 z-10 top-4  p-7 border-dashed border border-black`
                : `hidden`
            }
            ${
              i18next.language === "ar" ? `left-4` : `right-4`
            } lg:left-0 lg:right-0 lg:flex lg:w-auto lg:relative flex-col flex-shrink-0 bg-white`}
          >
            <div className="block lg:hidden">
              <GrClose
                className="float-right"
                onClick={() => toggleExpansion(!isExpanded)}
              />
            </div>
            <div className=" flex flex-col">
              <div className="mt-8 lg:mt-0 flex justify-end">
                <div className="lg:flex">
                  <div className="social-media-navbar-div">
                    <ul className="flex">
                      {data.site.siteMetadata.menuSocialMedia.map((item, i) => (
                        <li key={i} className="social-media-navbar-icon">
                          <a href={item.link}>
                            <img
                              className="w-4 h-4"
                              src={item.src}
                              alt="socialmedia"
                            ></img>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="lg:mx-2">
                    <div className="text-right lg:text-center">
                      <button
                        className="leading-8 ltr:font-majalla rtl:font-futurastd_light text-sm"
                        onClick={() => {
                          i18n.changeLanguage(langTargetToggle())
                        }}
                      >
                        {t("btn_locale")}
                      </button>
                    </div>
                  </div>
                  <div className="lg:mx-2">
                    <div className="text-right lg:text-center">
                      <button
                        onClick={() => {
                          togglePopup()
                        }}
                        className=" ltr:font-futurastd_book ltr:text-nav-header rtl:text-nav-header-ar rtl:font-majallab tracking-nav-header leading-8 "
                      >
                        {t("title_newsletter")}
                      </button>
                      {isOpen && <NewsletterPopup handleClose={togglePopup} />}
                    </div>
                  </div>
                  <div className="last lg:mx-2 float-right">
                    <form>
                      <div className="inline-flex">
                        <span className="input-group-btn">
                          <FaSearch />
                        </span>
                        <input
                          className="bg-search-text bg-no-repeat bg-center"
                          placeholder={" " + t("title_search")}
                          type="text"
                          name="keys"
                          size="60"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className=" text-label-fs-10 lg:flex-grow uppercase mt-5 lg:mt-10 font-futurastd_medium tracking-nav justify-end flex"> */}
              <div className=" ltr:text-label-fs-10 rtl:text-label-fs-15 lg:flex-grow uppercase mt-5 lg:mt-10 rtl:font-majallab ltr:font-futurastd_medium rtl:tracking-nav-header ltr:tracking-nav justify-end flex">
                <div className="w-full">{returnMenuList(data)}</div>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  )
}

export default withTrans(Navigation)
