import React from "react"
import { withTrans } from "../withTrans"

const NewsletterPopup = props => {
  return (
    <div className="popup-box  z-50">
      <div className="box md:w-1/2 border-style">
        <div className="lg:mx-32 lg:my-10">
          <span className="close-icon" onClick={props.handleClose}>
            x
          </span>
          <div className="font-futurastd_heavy uppercase w-full text-center text-xl">
            {props.t("title_cimatheque_newsletter")}
          </div>

          <form class="w-full max-w-xs uppercase font-futurastd_bold my-6">
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/4">
                <label
                  class="flex md:text-right mb-1 md:mb-0 pr-4"
                  for="inline-name"
                >
                   {props.t("title_name")}
                </label>
              </div>
              <div class="md:w-3/4">
                <input
                  class="bg-white appearance-none border-2 border-black rounded w-full py-2 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  id="inline-name"
                  type="text"
                />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/4">
                <label
                  class="flex md:text-right mb-1 md:mb-0 pr-4"
                  for="inline-email"
                >
                  {props.t("title_email")}
                </label>
              </div>
              <div class="md:w-3/4">
                <input
                  class="bg-white appearance-none border-2 border-black rounded w-full py-2 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  id="inline-email"
                  type="email"
                />
              </div>
            </div>
            <div class="md:flex md:justify-end">
              <div class="">
                <button
                  class="uppercase shadow bg-black focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="button"
                >
                  {props.t("title_subscribe")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default withTrans(NewsletterPopup)
