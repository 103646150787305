import React, { Fragment } from "react"
import { Link } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { withTrans } from "../withTrans"
import { localeLink } from "../utils/localeLink"

const Footer = ({t}) => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              footerLinks {
                link
                name
              }
            }
          }
        }
      `}
      render={data => (
        <div className="font-futurastd_light flex flex-col md:flex-row justify-evenly items-center h-16 text-black my-10 md:mx-48 text-nav-header tracking-nav">
          {data.site.siteMetadata.footerLinks.map((footerItem, i) => (
            <Fragment key={i}>
              <Link to={localeLink(footerItem.link)} className="md:mr-4">
                {t(footerItem.name)}
              </Link>
            </Fragment>
          ))}
        </div>
      )}
    />
  )
}

export default withTrans(Footer)
